import { Box, styled,Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { getAllBuilding, getGoogleUser } from "../../services/api"
import { useNavigate } from "react-router-dom"
import { getDistance } from "../map/utils"
import { useDispatch, useSelector } from "react-redux"
import { setBuildingDataReducer } from "../../redux/Buildings"
import { setVenuesDataReducer } from "../../redux/Venues"

//components
import Header from "./Header"
import Categories from "./Categories"
import VenueCard from "./VenueCard"


const createSetWithAttribute = (objects, attribute) => {
    const set = new Set();
    const seenValues = new Set();
    
    // Iterate through the objects
    objects.forEach(obj => {
        const value = obj[attribute]; // Get the value of the specified attribute
        
        // Check if the value has been seen before
        if (!seenValues.has(value)) {
            // If not, add the object to the set and mark the value as seen
            set.add(obj);
            seenValues.add(value);
        }
    });
    
    return set;
} 


const Home = () => {

    const dispatch = useDispatch()
    const venues = useSelector(state => state.venues.value)

    const [category, setCategory] = useState("All")
    const [gps, setGps] = useState()

    const navigate = useNavigate()

    if(navigator.geolocation) {
        navigator.geolocation.watchPosition((data) => {
            setGps({lat: data.coords.latitude, lng: data.coords.longitude})
        }, (error) => {
            console.log(error)
        }, {
            enableHighAccuracy: true,
            maximumAge: 0
        })
        
    }
    else {
        alert("Your phone does not support geolocation api")
    }


    useEffect(() => {
        const fetchData = async () => {
            let response = await getAllBuilding()
            // dispatch(setBuildingDataReducer(response.data))
            if(gps) {
                var arr = []
                // let nearestBuilding
                for(let i=0;i<response.data.length;i++) {
                    let distance = getDistance(gps, 
                        {
                            lat: response.data[i].coordinates[0], 
                            lng: response.data[i].coordinates[1]
                        }
                    )
                    arr.push({
                        ...response.data[i],
                        distance: distance
                    })
                }
                arr.sort((a,b) => {
                    return a.distance - b.distance
                })
                dispatch(setVenuesDataReducer([...createSetWithAttribute(arr, "venueName")]))

            }
            else {
                let arr = response.data
                arr.sort((a, b) => {
                    const nameA = a.venueName.toLowerCase()
                    const nameB = b.venueName.toLowerCase()

                    if(nameA < nameB) return -1
                    if(nameA > nameB) return 1
                    return 0
                })

                dispatch(setVenuesDataReducer([...createSetWithAttribute(arr, "venueName")]))
                dispatch(setVenuesDataReducer([...createSetWithAttribute(arr, "venueName")]))

            }

            dispatch(setBuildingDataReducer(response.data))

        }
        fetchData()      
    }, [gps])

    useEffect(() => {
    
        const fetchData = async () => {
          let response = await getGoogleUser()
          localStorage.setItem("refreshToken", response.data.refreshToken)
          localStorage.setItem("accessToken", response.data.accessToken)
          localStorage.setItem("userId", response.data.payload.userId)
        }
        fetchData()
    
      }, [])

    const openMap = (elm) => {
        navigate(`/${elm.venueName}?view=2d`)
    }

    return (
        <Box>
            <Box style={{
                boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.67)',
                position: 'sticky',
                background: '#fff',
                top: 0
            }}>
                <Header />
                <Categories category={category} setCategory={setCategory}/>
            </Box>
         
            <Box style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '20px 16px',
                gap: 16
            }}>
            {
                venues?.filter(elm => (category === "All" || category===elm.venueCategory))?.map(elm => (
                    <button 
                        onClick={() => openMap(elm)} 
                        key={elm._id}
                    >
                        <VenueCard data={elm} />
                    </button>
                ))
            }
            </Box>
            

        </Box>
    )
}

export default Home